import { AboutDataType } from "../types/data-type";

export const aboutData: AboutDataType = {
  title: "About Me",
  description1:
    "👋 Hello! I'm Roberto Gallardo, a passionate Full Stack software developer experienced in a variety of technologies.",
  description2:
    "💻 I primarily focus on backend development using C#. 🎨 I also have skills in frontend development with technologies like JavaScript, React, Vue.js, and Node.js. 🔌 My experience with sockets has allowed me to implement real-time communication. 🎨 In the realm of UI/UX, I've worked with design standards using Figma. 📲 I've created mobile applications using React Native CLI and Expo. 🌐 In terms of service integration, I've implemented REST APIs and SOAP protocols. 🔧 I'm familiar with code versioning tools like Git and 📚 I'm always committed to learning and staying updated with the latest technologies and market trends.",  
  image: 1,
};
