import React, { useContext } from "react";
import Marquee from "react-fast-marquee";
import { ThemeContext } from "../../../contexts/theme-context";
import { skillsData } from "../../../data/skillsData";
import { ContextValuesTypes } from "../../../types";
import { skillsImage } from "../../../utils/skills-image";
import "./skills.css";

function Skills() {
  const { theme } = useContext<ContextValuesTypes>(ThemeContext);

  const skillBoxStyle = {
    backgroundColor: theme.secondary,
    boxShadow: `0px 0px 12px ${theme.primary}`,
  };

  return (
    <div className='skills' style={{ backgroundColor: theme.secondary }}>
      <div className='skillsHeader'>
        <h2 style={{ color: theme.primary }}>Skills</h2>
      </div>
      <div className='skillsContainer'>
        <div className='skill--scroll'>
          <Marquee
            gradient={false}
            speed={80}
            pauseOnHover={true}
            pauseOnClick={true}
            delay={0}
            play={true}
            direction='left'>
            {skillsData.map((skill, id) => (
              <div className='skill--box' key={id} style={skillBoxStyle}>
                <img src={skillsImage(skill)} alt={skill} />
                <h3 style={{ color: theme.tertiary }}>{skill}</h3>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default Skills;
