import { EducationData } from "../types/data-type";

export const educationData: EducationData[] = [
  {
    id: 1,
    institution: "Universidad Veracruzana",
    course: "Bachelor of Administrative Computer Systems",
    startYear: "2015",
    endYear: "2019",
  },
  {
    id: 2,
    institution: "CBTIS 66",
    course: "Programming Technician",
    startYear: "2012",
    endYear: "2015",
  },
];
