/* eslint-disable */
import { ThemeDataType } from "../types/data-type";
import { contactsGreen } from "./images";

export const theLightTheme: ThemeDataType = {
  type: "light",
  primary: "#1D9BF0",
  quaternary: "#EFF3F4",
  quaternaryLight: "#F7F9F9",
  secondary: "#ffffff",
  tertiary: "#0f1419",
  buttonColor: "#0F1419",
  contactsimg: contactsGreen,
};

export const theDarkTheme: ThemeDataType = {
  type: "dark",
  primary: "#1DB954", 
  quaternary: "#1A1A1A", 
  quaternaryLight: "#8b98a5",
  secondary: "#121212", 
  tertiary: "#EFF3F4",
  buttonColor: "#8B98A5",
  contactsimg: contactsGreen,
};
