import certificate1 from "../assets/certificate/certificate01.jpg";
import certificate2 from "../assets/certificate/certificate02.jpg";
import certificate3 from "../assets/certificate/certificate03.png";
import certificate4 from "../assets/certificate/certificate04.png";
import certificate5 from "../assets/certificate/certificate05.png";
import certificate6 from "../assets/certificate/certificate06.png";
import certificate7 from "../assets/certificate/certificate07.jpg";
import certificate8 from "../assets/certificate/certificate08.jpg";
import certificate9 from "../assets/certificate/certificate09.jpg";
import certificate10 from "../assets/certificate/certificate10.png";
import certificate11 from "../assets/certificate/certificate11.jpg";
import certificate12 from "../assets/certificate/certificate12.jpg";
import certificate13 from "../assets/certificate/certificate13.jpg";
import certificate14 from "../assets/certificate/certificate14.jpg";
import certificate15 from "../assets/certificate/certificate15.jpg";
import certificate16 from "../assets/certificate/certificate16.jpg";
import { CertificateDataType } from "../types/data-type";

export const certificateData: CertificateDataType[] = [
  {
    id: "01",
    title: "Connecting to MongoDB in C#",
    image: certificate1,
    link: "https://learn.mongodb.com/c/C07-pO2qST-rzeApxWIzgw",
  },
  {
    id: "02",
    title: "MongoDB Aggregation with C#",
    image: certificate2,
    link: "https://learn.mongodb.com/c/b2QixvjXRUOyWl6rUBQV2Q",
  },
  {
    id: "03",
    title: "MongoDB CRUD Operations in C#",
    image: certificate3,
    link: "https://learn.mongodb.com/c/Y4cj8B2MSuCdO2_anIljDA",
  },
  {
    id: "04",
    title: "Using MongoDB with C#",
    image: certificate4,
    link: "https://learn.mongodb.com/c/mpqHVFHUTleiJnVqG8iXuA",
  },
  {
    id: "05",
    title: "Scrum Developer",
    image: certificate5,
    link: "https://www.scrum-institute.org/badges/19418805267196",
  },
  {
    id: "06",
    title: "CSS (Basic)",
    image: certificate6,
    link: "https://www.hackerrank.com/certificates/65ddd9469beb",
  },
  {
    id: "07",
    title: "JavaScript (Intermediate)",
    image: certificate7,
    link: "https://www.hackerrank.com/certificates/048dfcaa6bb7",
  },
  {
    id: "08",
    title: "Node (Basic)",
    image: certificate8,
    link: "https://www.hackerrank.com/certificates/426ae976f878",
  },
  {
    id: "09",
    title: "Node.js (Intermediate)",
    image: certificate9,
    link: "https://www.hackerrank.com/certificates/60063d463205",
  },
  {
    id: "10",
    title: "Problem Solving (Basic)",
    image: certificate10,
    link: "https://www.hackerrank.com/certificates/390d0c961629",
  },
  {
    id: "11",
    title: "Problem Solving (Intermediate)",
    image: certificate11,
    link: "https://www.hackerrank.com/certificates/68be3f847d9d",
  },
  {
    id: "12",
    title: "React (Basic)",
    image: certificate12,
    link: "https://www.hackerrank.com/certificates/deacd62dfb4f",
  },
  {
    id: "13",
    title: "Rest API (Intermediate)",
    image: certificate13,
    link: "https://www.hackerrank.com/certificates/3beab8a77c18",
  },
  {
    id: "14",
    title: "SQL (Advanced)",
    image: certificate14,
    link: "https://www.hackerrank.com/certificates/fb778ddf8401",
  },
  {
    id: "15",
    title: "SQL (Basic)",
    image: certificate15,
    link: "https://www.hackerrank.com/certificates/e4e2b91d8a27",
  },
  {
    id: "16",
    title: "SQL (Intermediate)",
    image: certificate16,
    link: "https://www.hackerrank.com/certificates/60cbf0e3aeee",
  },
];
