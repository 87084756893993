import { SocialsDataType } from "../types/data-type";

export const socialsData: SocialsDataType = {
  github: "https://github.com/robgallardof",
  facebook: "",
  linkedIn: "https://www.linkedin.com/in/robgallardof/",
  twitter: "",
  medium: "",
  stackOverflow: "",
  mail: "robgallardofl@gmail.com",
  youtube: "",
};
