import { HeaderDataType } from "../types/data-type";

export const headerData: HeaderDataType = {
  name: "RobertoGallardo",
  title: "Hello! I am",
  desciption:
    "Roberto Gallardo, a passionate Full Stack software developer with experience in a wide range of technologies. ",
  image: "https://i.imgur.com/zrNPkxk.jpg",
  imagebw: "",
  resumePdf:
    "https://drive.google.com/file/d/1t6dDRS8eUUbGuqnL_GLRlSxei_NZq7-W/view?usp=sharing",
};
