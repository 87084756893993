import { BlogDataType } from "../types/data-type";

export const blogData: BlogDataType[] = [
  {
    id: 1,
    title: "React.js: How to image drag and drop without any libraries.",
    description:
      "In this tutorial, we will try to make an Image drag and drop with a preview using React.js.",
    date: "Dec 7, 2022",
    image:
      "https://1.bp.blogspot.com/-OW7jX57tea4/XvnGxuEOslI/AAAAAAAABW0/R8lVT1AXDSwnvE0EGA9Ra49-LDm1ACwDgCK4BGAsYHg/s1216/wttr1.png",
    url: "https://medium.com/@abusaid-bd/react-js-how-to-image-drag-and-drop-without-any-libraries-b8c8dd11a29a",
  },
  {
    id: 2,
    title: "6 Javascript Basic Topic That Every Beginner Should Know.",
    description:
      "Variable stores a value for use next time on the code. Javascript declares a variable with the keyword var.",
    date: "Aug 11, 2022",
    image: "https://miro.medium.com/max/640/1*79VGolH_Ah0fD62quOelmA.webp",
    url: "https://medium.com/@abusaid-bd/6-javascript-basic-topic-that-every-beginner-should-know-532599c626ba",
  },

  {
    id: 3,
    title: "React.js: How to image drag and drop without any libraries.",
    description:
      "In this tutorial, we will try to make an Image drag and drop with a preview using React.js.",
    date: "Dec 7, 2022",
    image:
      "https://1.bp.blogspot.com/-OW7jX57tea4/XvnGxuEOslI/AAAAAAAABW0/R8lVT1AXDSwnvE0EGA9Ra49-LDm1ACwDgCK4BGAsYHg/s1216/wttr1.png",
    url: "https://medium.com/@abusaid-bd/react-js-how-to-image-drag-and-drop-without-any-libraries-b8c8dd11a29a",
  },
  {
    id: 4,
    title: "6 Javascript Basic Topic That Every Beginner Should Know.",
    description:
      "Variable stores a value for use next time on the code. Javascript declares a variable with the keyword var.",
    date: "Aug 11, 2022",
    image:
      "https://1.bp.blogspot.com/-OW7jX57tea4/XvnGxuEOslI/AAAAAAAABW0/R8lVT1AXDSwnvE0EGA9Ra49-LDm1ACwDgCK4BGAsYHg/s1216/wttr1.png",
    url: "https://medium.com/@abusaid-bd/6-javascript-basic-topic-that-every-beginner-should-know-532599c626ba",
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below.

/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/
