import React from "react";
import { FaCode, FaPlay } from "react-icons/fa";
import placeholder from "../../../assets/png/placeholder.png";
import { ProjectCardUIPops } from "../../../types/project";
import "./project-card.css";
const Fade = require("react-reveal/Fade");

function ProjectCardUI({
  id,
  name,
  desc,
  tags,
  code,
  demo,
  image,
  theme,
  classes,
}: ProjectCardUIPops) {
  return (
    <Fade bottom>
      <div
        key={id}
        className='singleProject'
        style={{ backgroundColor: theme.quaternary }}>
        <div className='projectContent'>
          <h2
            id={name.replace(" ", "-").toLowerCase()}
            style={{ color: theme.tertiary }}>
            {name}
          </h2>
          <img src={image ? image : placeholder} alt={name} />
          <div className='project--showcaseBtn'>
            <a
              href={demo}
              target='_blank'
              rel='noreferrer'
              className={classes.iconBtn}
              aria-labelledby={`${name.replace(" ", "-").toLowerCase()} ${name
                .replace(" ", "-")
                .toLowerCase()}-demo`}>
              <FaPlay
                id={`${name.replace(" ", "-").toLowerCase()}-demo`}
                className={classes.icon}
                aria-label='Demo'
              />
            </a>
            <a
              href={code}
              target='_blank'
              rel='noreferrer'
              className={classes.iconBtn}
              aria-labelledby={`${name.replace(" ", "-").toLowerCase()} ${name
                .replace(" ", "-")
                .toLowerCase()}-code`}>
              <FaCode
                id={`${name.replace(" ", "-").toLowerCase()}-code`}
                className={classes.icon}
                aria-label='Code'
              />
            </a>
          </div>
        </div>
        <p
          className='project--desc'
          style={{
            background: theme.secondary,
            color: theme.tertiary,
          }}>
          {desc}
        </p>
        <div
          className='project--lang'
          style={{
            background: theme.secondary,
            color: theme.tertiary,
          }}>
          {tags.map((tag, id) => (
            <span key={id}>{tag}</span>
          ))}
        </div>
      </div>
    </Fade>
  );
}

export default ProjectCardUI;
