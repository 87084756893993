import { ExperienceDataType } from "../types/data-type";

export const experienceData: ExperienceDataType[] = [
  {
    id: 1,
    company: "GEODIS",
    jobtitle: "Full-Stack Developer",
    startYear: "Feb 2023",
    endYear: "Present",
  },
  {
    id: 2,
    company: "BANSÍ",
    jobtitle: "Systems Development Coordinator B",
    startYear: "Feb 2022",
    endYear: "Feb 2023",
  },
  {
    id: 3,
    company: "MEGACABLE",
    jobtitle: "Senior Developer",
    startYear: "Nov 2019 ",
    endYear: "Feb 2022",
  },
];
